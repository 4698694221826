import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { Amplify, Auth } from 'aws-amplify';
import toast, { Toaster } from 'react-hot-toast';
import { useApp } from '../../store/useApp';
import { UserRolesConsoles } from './UserRolesConsoles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Loading from '../../components/Loading';
import PurpleButton from './purplebutton';
import EmergencyButton from './emergencybutton';
let cognitoUserData = null;
const Login = () => {
  /* 	const [loading, setLoading] = useState(); */
  const [environmentData, setEnvironmentData] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState({
    loading: false,
    entries: [],
    toDashboard: false,
    keyword: '',
    globalInstaceId: '',
    userId: '',
    globalpass: true,
    value: '',
    email: '',
    password: '',
    otppassword: '',
    newpassword: '',
    confirmpassword: '',
    newforgotpassword: '',
    newforgototp: '',
    uservalue: '',
    setnewpassword: false,
    setotp: false,
    forgotpassword: false,
    successforgotpassword: false,
    openpassword: false,
    showbutton: false,
    showbaebutton: false,
    showemergencybutton: false,
    errormessage1: '',
    errormessage2: '',
    errormessage3: '',
    errormessage4: '',
    disablesignin: false,
    successchangepassword: false,
    resendCodeNotifDisplay: 'none',
    cognitoUserData: null,
    confirm: true,
    showConfirm: true,
  });

  const setUser = useApp((state) => state.setUser);
  const setEnvironment = useApp((state) => state.setEnvironment);
  const setAuth = useApp((state) => state.setAuth);

  const navigate = useNavigate();
  const location = useLocation();

  const pathname = window.location.pathname;
  const isTrailingSlashEndUrl = pathname.at(-1) === '/';

  if (isTrailingSlashEndUrl) {
    window.history.pushState('', '', pathname.slice(0, -1));
  }

  useEffect(() => {
    const expirityTimestamp = localStorage.getItem('expirity_timestamp');
    const defaultSecurityProfile = localStorage.getItem(
      'defaultSecurityProfile'
    );
    const idToken = localStorage.getItem('idToken');

    if (!expirityTimestamp || !defaultSecurityProfile || !idToken) {
      localStorage.clear();
      return () => {};
    }

    if (expirityTimestamp < Date.now() / 1000) return () => {};
    if (defaultSecurityProfile === 'undefined') {
      localStorage.clear();
      return () => {};
    }

    if (!defaultSecurityProfile) return () => {};
    if (!UserRolesConsoles[defaultSecurityProfile]) {
      toast(
        `No console found for the ${defaultSecurityProfile} securityProfile...`
      );
      return () => {};
    }

    if (defaultSecurityProfile?.toLowerCase().startsWith('cqm')) {
      navigate('/CQM');
      return () => {};
    }

    let appPath = UserRolesConsoles[defaultSecurityProfile];
    if (!defaultSecurityProfile.includes('agent')) {
      toast(
        `Please wait a while. You are being redirecting to ${appPath} console...`
      );

      const timer = setTimeout(() => {
        window.open(
          `${window.location.href.split('#')[0]}/${appPath}`,
          '_self'
        );
      }, [3000]);

      return () => {
        clearTimeout(timer);
      };
    }

    appPath = 'agentccp';
    toast(`Please wait a while. Checking your ${appPath} console status...`);
    const timer = setTimeout(() => {
      const isAgentCCPOpen = localStorage.getItem('isAgentCCPOpen');
      if (isAgentCCPOpen !== 'active') {
        toast(`You are being redirecting to ${appPath} console...`);
        setTimeout(() => {
          window.open(
            `${window.location.href.split('#')[0]}/${appPath}`,
            '_self'
          );
        }, [3000]);
        return;
      }

      toast(`The ${appPath} console is already opened...`);
    }, [3000]);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (window.location.href.includes('/#')) {
      const paramsArray = window.location.href.split('#')[1].split('&');
      let params = {};
      for (let index = 0; index < paramsArray.length; index++) {
        const element = paramsArray[index];
        const elementArray = element.split('=');
        params[elementArray[0]] = elementArray[1];
      }

      for (const key in params) {
        /* 		if (Object.hasOwnProperty.call(params, key)) { */
        const element = params[key];
        localStorage.setItem(key, element);
        /* } */
      }

      const expirityTimestamp =
        Math.floor(new Date().getTime() / 1000) + +params.expires_in;
      localStorage.setItem('expirity_timestamp', expirityTimestamp);
      //handleRedirection();
    }
  }, []);

  useEffect(() => {
    const fetchEnv = async () => {
      const result = await axios.post(
        'https://zqblwveml7.execute-api.eu-central-1.amazonaws.com/prod/asd-backend-get-environment-data-v2',
        {
          url: window.location.href,
          project: 'login',
        }
      );
      setEnvironmentData(result.data);
      setEnvironment(result.data);
    };

    fetchEnv();
  }, []);

  useEffect(() => {
    if (environmentData) {
      const auth = initCognitoSDK(environmentData);
      const curUrl = window.location.href;
      auth.parseCognitoWebResponse(curUrl);
    }
  }, [environmentData]);

  const handleDXCLogin = () => {
    const auth = initCognitoSDK(environmentData);
    auth.getSession();
  };

  const handleEmergencyLogin = async (step) => {
    switch (step) {
      case 'prepare':
        setState({ ...state, openpassword: true });
        break;
      case 'process':
        try {
          setState({ ...state, loading: true });
          if (state.email && state.password !== '') {
            Amplify.configure({
              Auth: {
                region: environmentData.Region,
                userPoolId: environmentData.UserPoolId,
                userPoolWebClientId: environmentData.ClientId,
                authenticationFlowType: 'CUSTOM_AUTH',
                oauth: {
                  responseType: 'code',
                },
              },
            });
            const cognitoUser = await Auth.signIn(state.email, state.password);
            cognitoUserData = cognitoUser;
            if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
              setState({ ...state, setnewpassword: true, loading: false });
            } else if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
              setState({ ...state, setotp: true, loading: false });
            } else {
            }
          } else {
            toast.error(`The username or password is required.`);
          }
        } catch (error) {
          if (error.code === 'NotAuthorizedException') {
            if (!state.value.toLowerCase().includes('dxc.com')) {
              setState({
                ...state,
                loading: false,
                showbutton: false,
              });
              toast.error(`The username or password is incorrect.`);
            } else {
              toast.error(`Please enter valid username or emailID`);
            }
          }
        }

        break;
      default:
        break;
    }
  };

  const handleRedirection = async (cognitoSession, type) => {
    try {
      const payload = cognitoSession.getIdToken().decodePayload();
      const email = payload.email;
      const accessToken = cognitoSession.getAccessToken().getJwtToken();
      const idToken = cognitoSession.getIdToken().getJwtToken();
      const code = searchParams.get('code');

      localStorage.clear();
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('expirity_timestamp', payload.exp);
      localStorage.setItem('user_id', email);
      localStorage.setItem('idToken', idToken);
      localStorage.setItem('globalidToken', idToken);

      const auth = {
        payload,
        email,
        accessToken,
        idToken,
      };

      const { data: envData } = await axios.post(
        'https://zqblwveml7.execute-api.eu-central-1.amazonaws.com/prod/asd-backend-get-environment-data-v2',
        {
          url: window.location.href,
          project: 'login',
        }
      );

      const response = await axios.post(
        `${envData.ApiUrl}/asd-backend-user-v2`,
        {
          UserId: email,
          operation: 'getUserconfigData',
        }
      );

      const userData = response.data;

      if (!userData) return toast('No user found...');

      setUser(userData);
      setAuth(auth);

      localStorage.setItem(
        'selected_instance_name',
        userData?.ASD_Instance?.split(',')[1]
      );
      localStorage.setItem('selected_instance', userData?.ASD_Instance);
      localStorage.setItem(
        'defaultSecurityProfile',
        userData?.DefaultSecurityProfile?.toLowerCase()
      );

      if (!userData.DefaultSecurityProfile) {
        navigate('/403');
        localStorage.clear();
        return;
      }

      if (!UserRolesConsoles[userData.DefaultSecurityProfile?.toLowerCase()]) {
        toast(
          `No console found for the ${userData.DefaultSecurityProfile} securityProfile...`
        );
        return;
      }

      if (
        userData.SecurityProfiles?.length === 1 &&
        userData.SecurityProfiles?.at(0)?.toLowerCase().startsWith('cqm')
      ) {
        localStorage.setItem('defaultSecurityProfile', 'CQM');
        return navigate('/CQM');
      }

      let appPath = '';
      if (userData.DefaultSecurityProfile?.toLowerCase() === 'agent')
        appPath = 'agentccp';
      else
        appPath =
          UserRolesConsoles[userData.DefaultSecurityProfile?.toLowerCase()];

      window.location.assign(
        `${window.location.href.split('#')[0]}/${appPath}`
      );
    } catch (error) {
      toast.error(`Ouch, something went wrong... \n\n ${error.message}`);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !state.confirm) {
      handleLogin();
    }
  };

  const handleLogin = () => {
    switch (true) {
      case state.email.includes('@dxc.com'):
      case state.email.includes('@DXC.COM'):
        setState({
          ...state,
          showbutton: true,
          showemergencybutton: true,
          showConfirm: false,
        });
        break;
      case state.email.includes('@baesystems.com'):
      case state.email.includes('@BAESYSTEMS.COM'):
        setState({
          ...state,
          showbaebutton: true,
          showemergencybutton: true,
          showConfirm: false,
        });
        break;
      default:
        setState({
          ...state,
          showbaebutton: false,
          showbutton: false,
          showemergencybutton: true,
          showConfirm: false,
        });
        break;
    }
  };

  const initCognitoSDK = (data) => {
    const authData = {
      ClientId: data.ClientId,
      AppWebDomain: data.AppWebDomain,
      TokenScopesArray: data.TokenScopesArray,
      UserPoolId: data.UserPoolId,
      RedirectUriSignIn: window.location?.href?.split('?')[0],
      RedirectUriSignOut: window.location.href,
      AdvancedSecurityDataCollectionFlag: false,
    };

    let auth = new CognitoAuth(authData);

    auth.userhandler = {
      onSuccess: function (result) {
        handleRedirection(result, 'dxc');
      },
      onFailure: function (err) {},
    };
    //auth.useCodeGrantFlow();

    return auth;
  };

  const handleForgetPassword = async () => {
    if (!state.uservalue.includes('@')) {
      toast('Please enter a valid email...');
      return;
    }

    const password = 'asd_sercuity_watchguard';
    var CryptoObject = CryptoJS.AES.encrypt(state.uservalue, password);
    var username = CryptoObject.toString();

    try {
      const otpResponse = await axios.post(environmentData.LoginOtpUrl, {
        username,
      });

      setState((current) => ({
        ...current,
        forgotpassword: false,
        successforgotpassword: true,
      }));
    } catch (error) {
      toast.error(
        `Ouch, something went wrong... \n\n OtpResponse: ${error.message}`
      );
    }
  };

  const handlecancelforgotpassword = () => {
    setState((current) => ({
      ...current,
      forgotpassword: false,
    }));
  };

  const handlecancelsendotp = () => {
    setState((current) => ({
      ...current,
      successforgotpassword: false,
    }));
  };

  const handleSendOtp = async () => {
    if (!state.newforgototp.length) {
      toast('Please enter a valid identification code...');
      return;
    }

    try {
      Amplify.configure({
        Auth: {
          region: environmentData.Region,
          userPoolId: environmentData.UserPoolId,
          userPoolWebClientId: environmentData.ClientId,
        },
      });

      const cognitoUser = await Auth.signIn(
        state.uservalue,
        state.newforgototp
      );

      if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setState((current) => ({
          ...current,
          setnewpassword: true,
          successforgotpassword: false,
        }));
      } else {
      }
    } catch (err) {
      setState((current) => ({
        ...current,
        errormessage2: 'Incorrect identification code!',
      }));
      setTimeout(() => {}, 3000);
    }
  };

  const handleSubmitNewPassword = async (e) => {
    e.preventDefault();

    if (state.newpassword === state.confirmpassword) {
      try {
        const cognitoUser = await Auth.signIn(
          state.uservalue,
          state.newforgototp
        );

        const loggedUser = await Auth.completeNewPassword(
          cognitoUser, // the Cognito User Object
          state.newpassword // the new password
        );

        let result = loggedUser;

        setState((current) => ({
          ...current,
          successchangepassword: true,
          setnewpassword: false,
        }));
      } catch (error) {
        setState((current) => ({
          ...current,
          loading: false,
        }));
        toast.error(error.message);
      }
    } else {
      setState((current) => ({
        ...current,
        loading: false,
      }));
      toast.error('New Password and Confirm Password are not matching');
    }
  };

  const handlecancelsetnewpassword = () => {
    setState((current) => ({
      ...current,
      setnewpassword: false,
    }));
  };

  const handleSuccessChangePassword = () => {
    setState((current) => ({
      ...current,
      successchangepassword: false,
    }));
  };
  const handleSubmitOTP = async (event) => {
    event.preventDefault();
    setState({
      ...state,
      errormessage1: '',
      loading: true,
    });
    try {
      if (state.otppassword !== '') {
        let cognitoUserFound = await Auth.sendCustomChallengeAnswer(
          cognitoUserData,
          state.otppassword
        );

        if (cognitoUserFound.signInUserSession == null) {
          toast.error(`Wrong OTP !`);
        } else {
          handleRedirection(cognitoUserFound.signInUserSession);
        }
      } else {
        toast.error(`OTP is required !`);
      }
    } catch (error) {
      if (error.message == 'Incorrect username or password.') {
        toast.error(
          `You have exceeded the limits to enter OTP, Please Signin again`
        );
        setTimeout(() => {
          setState({ setotp: false });
        }, 3000);
      }
    }
  };
  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          className: '',
          style: {
            textAlign: 'start',
          },
        }}
      />
      <div className='loginContainer'>
        {/* {state.loading && <Loading />} */}
        <div className='fullbgc'>
          <div style={{ position: 'absolute', bottom: '5%', left: '5%' }}>
            <div className='footer'>
              &copy; DXC Technology Company {new Date().getFullYear()}
            </div>
          </div>

          <div style={{ position: 'absolute', bottom: '5%', right: '5%' }}>
            <div className='footer' title='Version'>
              Agile Service Desk (ASD) Ver 2.0, Agent CCP Ver 2.1
            </div>
          </div>
        </div>
        {!(
          state.forgotpassword ||
          state.successforgotpassword ||
          state.setnewpassword ||
          state.setotp ||
          state.successchangepassword
        ) && (
          <>
            <Paper elevation={3} className='rounded overlay vcenter'>
              <div className='left_login'>
                <div className='head'>
                  <div className='grp164'></div>
                  <div className='text'>Agile Service Desk</div>
                </div>
                <div className='subhead'>
                  Agile Service Desk is a cloud-based contact center platform
                  built by DXC using Amazon Connect and Amazon Web Services.
                </div>
                <div className='dxc'></div>
              </div>
              <div className='right_login'>
                <form
                  className='form'
                  noValidate
                  autoComplete='off'
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className='label'>Email</div>
                  <TextField
                    label='Enter your full Email Address'
                    defaultValue='Normal'
                    size='medium'
                    className='inputText'
                    variant='outlined'
                    value={state.email}
                    // disabled={this.state.disablesignin}
                    onChange={(e) => {
                      setState({ ...state, email: e.target.value });
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  {!state.openpassword && (
                    <PurpleButton
                      title='Submit'
                      onClick={handleLogin}
                      disabled={
                        state.confirm ||
                        state.showbutton ||
                        state.showbaebutton ||
                        state.showemergencybutton
                      }
                    />
                  )}
                  {state.openpassword && (
                    <>
                      <div className='label'>Password</div>
                      <TextField
                        type='password'
                        label='Password'
                        className='inputText'
                        variant='outlined'
                        value={state.password}
                        // disabled={this.state.disablesignin}
                        onChange={(e) => {
                          setState({ ...state, password: e.target.value });
                        }}
                        onKeyPress={handleKeyPress}
                      />
                    </>
                  )}
                  {!state.openpassword &&
                    (state.showbutton ||
                      state.showbaebutton ||
                      state.showemergencybutton) && (
                      <div className='label'>Login Options</div>
                    )}
                  {!state.openpassword && state.showbutton && (
                    // <Button variant="contained" color="primary" className="submit" onClick={this.handlegloballogin}>
                    //   DXC Universal ID Login
                    // </Button>
                    <PurpleButton
                      title='DXC Universal ID Login'
                      onClick={handleDXCLogin}
                    />
                  )}
                  {!state.openpassword && state.showbaebutton && (
                    // <Button variant="contained" color="primary" className="submit" onClick={this.handlegloballogin}>
                    //   BAE Systems Ping SSO
                    // </Button>
                    <PurpleButton
                      title='BAE Systems Ping SSO'
                      onClick={handleDXCLogin}
                    />
                  )}

                  {!state.openpassword && state.showemergencybutton && (
                    // <Button variant="contained" color="primary" className="submit emergency" onClick={this.handleEmergencyLogin}>
                    //   ASD Emergency Login
                    // </Button>
                    <EmergencyButton
                      title='ASD Emergency Login'
                      onClick={() => handleEmergencyLogin('prepare')}
                    />
                  )}

                  {state.openpassword && (
                    <>
                      {/* <Button variant="contained" color="primary" className="submit" onClick={this.handleSubmit}>
												Login
											</Button> */}
                      <PurpleButton
                        title='Login'
                        onClick={() => handleEmergencyLogin('process')}
                      />
                      <div className='forgot_password'>
                        <a
                          className='hover'
                          onClick={() => {
                            setState((current) => ({
                              ...current,
                              forgotpassword: true,
                            }));
                          }}
                        >
                          Forgot Password?
                        </a>
                      </div>
                    </>
                  )}
                  {state.showConfirm && (
                    <div className='flex flex-col justify-between text-justify px-[4%] font-arial text-[17px] leading-[1.2] mt-6'>
                      <div className='flex flex-col gap-4 px-[4%]'>
                        <span>
                          I affirm that I am authorized to access and utilize
                          this system solely within the scope of my role as an
                          employee or as a subcontracted resource of DXC
                          Technology Company.
                        </span>
                        <span>
                          I confirm that the privileges and access rights
                          granted to me are necessary for my role and will be
                          used strictly for the intended purpose of my duties at
                          DXC Technology Company.
                        </span>
                        <span>
                          Furthermore, I agree to protect all accessible
                          proprietary, confidential, or privileged information,
                          as well as any data or information that is legally
                          exempt from disclosure.
                        </span>
                        <span>
                          I understand that any violation of these terms may
                          result in immediate revocation of my access rights,
                          disciplinary action, and potential legal consequences.
                        </span>
                      </div>
                      <div className='flex items-center px-[4%] mt-4 xl:mt-[10%] gap-4'>
                        <input
                          className='h-[30px] w-[30px] cursor-pointer accent-[#5f249f]'
                          id='confirm'
                          type='checkbox'
                          onChange={() => {
                            setState((current) => ({
                              ...current,
                              confirm: !current.confirm,
                            }));
                          }}
                        />
                        <label
                          className='text-[24px] select-none cursor-pointer'
                          htmlFor='confirm'
                        >
                          I confirm all the above
                        </label>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </Paper>
          </>
        )}
        {state.forgotpassword && (
          <>
            <div className='fullbg'></div>
            <div className='overlay forgot_password_overlay vcenter'>
              <div className='head'>
                <div className='grp164'></div>
                <div className='text'>Agile Service Desk</div>
              </div>
              <Paper className='rounded' style={{ padding: '10px' }}>
                <div className='title'>Forgot Password</div>
                <div className='step'>
                  Step <b>1</b> of 3
                </div>
                <div className='verify'>Verify your identity</div>
                <TextField
                  type='text'
                  label='Enter Email Id'
                  className='inputText'
                  variant='outlined'
                  value={state.uservalue}
                  onChange={(e) => {
                    setState({ ...state, uservalue: e.target.value });
                  }}
                />
                <Button
                  className='left_login'
                  color='primary'
                  onClick={handlecancelforgotpassword}
                >
                  Cancel
                </Button>
                <Button
                  className='right_login'
                  variant='contained'
                  color='primary'
                  onClick={handleForgetPassword}
                >
                  Next
                </Button>
              </Paper>
            </div>
          </>
        )}
        {state.successforgotpassword && (
          <>
            <div className='fullbg'></div>
            <div className='overlay forgot_password_overlay step2 vcenter'>
              <div className='head'>
                <div className='grp164'></div>
                <div className='text'>Agile Service Desk</div>
              </div>
              <Paper className='rounded step2' style={{ padding: '10px' }}>
                <div className='title'>Forgot Password</div>
                <div className='step'>
                  Step <b>2</b> of 3
                </div>
                <div className='temporary'>
                  Your temporary identification code is on its way. Once you
                  receive it, please enter it below.
                </div>
                <div
                  className='resendCodeNotification'
                  style={{ display: state.resendCodeNotifDisplay }}
                >
                  We have sent temporary code to your email.
                </div>
                <TextField
                  type='text'
                  label='Enter temporary identification code'
                  className='inputText'
                  variant='outlined'
                  value={state.newforgototp}
                  onChange={(e) => {
                    setState({ ...state, newforgototp: e.target.value });
                  }}
                />
                <div className='never'>
                  {state.errormessage2.length > 0 && (
                    <span style={{ color: '#AA0000' }}>
                      {state.errormessage2}
                      <br />
                      <br />
                    </span>
                  )}
                  Your identification code never arrived?{' '}
                  <a
                    className='hover'
                    onClick={() => {
                      handleForgetPassword();
                      setState({ ...state, resendCodeNotifDisplay: 'block' });
                    }}
                  >
                    Resend Code.
                  </a>
                </div>
                <Button
                  className='left_login'
                  color='primary'
                  onClick={handlecancelsendotp}
                >
                  Cancel
                </Button>
                <Button
                  className='right_login'
                  variant='contained'
                  color='primary'
                  onClick={handleSendOtp}
                >
                  Next
                </Button>
              </Paper>
            </div>
          </>
        )}
        {state.setnewpassword && (
          <>
            <div className='fullbg'></div>
            <div className='overlay forgot_password_overlay step3 vcenter'>
              <div className='head'>
                <div className='grp164'></div>
                <div className='text'>Agile Service Desk</div>
              </div>
              <Paper className='rounded step3' style={{ padding: '10px' }}>
                <div className='title'>Forgot Password</div>
                <div className='step'>
                  Step <b>3</b> of 3
                </div>
                <div className='temporary'>
                  You are almost done, please tell us your new password.
                </div>
                <div className='emailLabel'>
                  Email Id: <b>{state.uservalue}</b>
                </div>
                <TextField
                  type='password'
                  label='Enter New Password'
                  className='inputText'
                  variant='outlined'
                  value={state.newpassword}
                  onChange={(e) => {
                    setState({ ...state, newpassword: e.target.value });
                  }}
                />
                <TextField
                  type='password'
                  label='Confirm New Password'
                  className='inputText'
                  variant='outlined'
                  value={state.confirmpassword}
                  onChange={(e) => {
                    setState({ ...state, confirmpassword: e.target.value });
                  }}
                />
                {state.errormessage3.length > 0 && (
                  <div className='error'>{state.errormessage3}</div>
                )}
                <div className='password_help'>
                  <b>Password Help</b>
                  <br />
                  Minimum of 8 characters
                  <br />
                  At least two of the following
                  <br />
                  - 1 Letter (Case sensitive)
                  <br />
                  - 1 Number
                  <br />
                  - 1 of the special characters (@!$#%&^~+?/)
                  <br />
                  No more than 2 identical sequential characters (111, 222, 333,
                  abc, !!!)
                </div>
                <Button
                  className='left_login'
                  color='primary'
                  onClick={handlecancelsetnewpassword}
                >
                  Cancel
                </Button>
                <Button
                  className='right_login'
                  variant='contained'
                  color='primary'
                  onClick={handleSubmitNewPassword}
                >
                  Submit
                </Button>
              </Paper>
            </div>
          </>
        )}
        {state.setotp && (
          <>
            <Paper elevation={3} className='rounded overlay vcenter'>
              <div className='left_login'>
                <div className='head'>
                  <div className='grp164'></div>
                  <div className='text'>Agile Service Desk</div>
                </div>
                <div className='subhead'>
                  Agile Service Desk is a cloud-based contact center platform
                  built by DXC using Amazon Connect and Amazon Web Services.
                </div>
                <div className='dxc'></div>
              </div>
              <div className='right_login'>
                <form className='form' noValidate autoComplete='off'>
                  <div className='label'>
                    One Time Passcode has been sent to your Email address
                  </div>
                  <TextField
                    label='Enter One-Time Passcode'
                    className='inputText'
                    variant='outlined'
                    value={state.otppassword}
                    onChange={(e) => {
                      setState({ ...state, otppassword: e.target.value });
                    }}
                  />
                  <div className='never half' style={{ textAlign: 'left' }}>
                    You have only <b className='error'>3</b> attempts left.{' '}
                    <a
                      className='hover'
                      onClick={() => handleEmergencyLogin('process')}
                    >
                      Resend OTP
                    </a>
                  </div>
                  <PurpleButton title='Submit' onClick={handleSubmitOTP} />
                </form>
              </div>
            </Paper>
          </>
        )}
        {state.successchangepassword && (
          <>
            <div className='fullbg'></div>
            <div className='overlay forgot_password_overlay step2 vcenter'>
              <div className='head'>
                <div className='grp164'></div>
                <div className='text'>Agile Service Desk</div>
              </div>
              <Paper
                className='rounded step1'
                style={{ textAlign: 'center', padding: '10px' }}
              >
                <div className='successIcon' />
                <br />
                <div className='passwordChanged'>
                  Password Changed Successfully
                </div>
                <div className='passwordChangedSub'>
                  You can now login with your new password
                </div>
                <PurpleButton
                  title='Login Now'
                  onClick={handleSuccessChangePassword}
                  style={{ margin: '5% auto', padding: '15px', width: '146pt' }}
                />
              </Paper>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Login;
