/**
 * Copyright © 2019-2022 DXC. All rights reserved.
 */
import React, { Component, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import Route from '../src/route';
//const Login = lazy(() => import('./screens/Login/login'));
import Login from './screens/Login/login';
import NotFound from './screens/NotFound/NotFound';

import { CQM } from './screens/CQM/CQM';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';

const Loading = () => <h1>Loading...</h1>;
class App extends Component {
  render() {
    return (
      /* 	<Suspense fallback={<Loading />}> */
      <Router basename='/v2'>
        <Routes>
          <Route path='/' exact element={<Login />} />
          <Route
            path='/CQM'
            exact
            element={
              <ProtectedRoute allowedRole='cqm'>
                <CQM />
              </ProtectedRoute>
            }
          />
          <Route path='*' exact element={<NotFound />} />
        </Routes>
      </Router>
      /* 	</Suspense> */
    );
  }
}

export default App;
