import React from 'react';
import PropTypes from 'prop-types';
import { ModalRedirect } from './ModalRedirect';
import { useApp } from '../../store/useApp';

const ProtectedRoutePropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const ProtectedRoute = ({ children, allowedRole }) => {
  const expTokenTime = Number(localStorage.getItem('expirity_timestamp'));
  const nowUnixTime = Math.floor(new Date().getTime() / 1000);

  const { SecurityProfiles } = useApp((state) => state.user);

  if (nowUnixTime > expTokenTime)
    return (
      <ModalRedirect
        title={'Session expired...'}
        subTitle={'Your session has timed out.'}
        description={'You will be redirected to the Login page.'}
        btnLabel={'Renew Session'}
        timeInSeconds={10}
        deleteLocalStorage={true}
      />
    );

  if (allowedRole) {
    const isAllowed = SecurityProfiles?.some((role) =>
      role?.toLowerCase().startsWith(allowedRole)
    );

    if (!isAllowed)
      return (
        <ModalRedirect
          title={'Ouch...'}
          subTitle={'You are not authorized to access this page.'}
          description={'You will be redirected to the Home page.'}
          btnLabel={'Home'}
          timeInSeconds={10}
          deleteLocalStorage={false}
        />
      );
  }

  return children;
};

ProtectedRoute.propTypes = ProtectedRoutePropTypes;

ProtectedRoute.defaultProps = {
  children: undefined,
};

// Math.floor(new Date().setMinutes(new Date().getMinutes() + 2) / 1000);
