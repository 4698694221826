import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ModalRedirectPropTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  btnLabel: PropTypes.string,
  timeInSeconds: PropTypes.number,
  deleteLocalStorage: PropTypes.bool,
};

const CountDownPropTypes = {
  countdownTimestampUnix: PropTypes.number,
  timeInSeconds: PropTypes.number,
  deleteLocalStorage: PropTypes.bool,
};

export const ModalRedirect = ({
  title,
  subTitle,
  description,
  btnLabel,
  timeInSeconds,
  deleteLocalStorage,
}) => {
  const countdownTimestampUnix = useMemo(
    () => Math.floor(new Date().getTime() / 1000) + timeInSeconds,
    []
  );

  return (
    <div className='absolute inset-0 z-[2000] flex items-center justify-center backdrop-blur bg-[#eaeaea]'>
      <div className='flex p-4 bg-[#E2E2E4] rounded max-w-[500px] gap-[15px]'>
        <CountDown
          countdownTimestampUnix={countdownTimestampUnix}
          timeInSeconds={timeInSeconds}
          deleteLocalStorage={deleteLocalStorage}
        />
        <div className='flex flex-col gap-[15px]'>
          <span className='text-[20px] font-bold leading-[20px] text-left'>
            {title}
          </span>
          <p className='text-left'>
            {subTitle} <br></br> {description}
          </p>

          <button
            className='bg-[#4F46E5] p-2 px-6 rounded w-fit text-[#fff] text-[16px] font-[Arial] font-bold'
            onClick={() => {
              if (deleteLocalStorage) {
                localStorage.clear();
              }

              window.location.href = `/v2`;
            }}
          >
            {btnLabel}
          </button>
        </div>
      </div>
    </div>
  );
};

function CountDown({
  countdownTimestampUnix,
  timeInSeconds,
  deleteLocalStorage,
}) {
  const [remainingTime, setRemainingTime] = useState({
    seconds: timeInSeconds,
  });

  useEffect(() => {
    const nowUnixTime = Math.floor(new Date().getTime() / 1000);

    if (nowUnixTime > countdownTimestampUnix) {
      if (deleteLocalStorage) {
        localStorage.clear();
      }

      window.location.href = `/v2`;

      return () => {};
    }

    const interval = setInterval(() => {
      setRemainingTime((current) => ({
        ...current,
        seconds: countdownTimestampUnix - nowUnixTime,
      }));
    }, 1000);

    return () => clearInterval(interval);
  }, [remainingTime]);

  return (
    <div className='h-[50px] w-[50px] border-2 border-[#DC2626] rounded-[50%] flex items-center justify-center'>
      <span className='font-bold text-[#DC2626]'>{remainingTime.seconds}</span>
    </div>
  );
}

CountDown.propTypes = CountDownPropTypes;

CountDown.defaultProps = {
  countdownTimestampUnix: undefined,
  timeInSeconds: undefined,
};

ModalRedirect.propTypes = ModalRedirectPropTypes;

ModalRedirect.defaultProps = {
  timeInSeconds: undefined,
};
