import api from '../utils/axios/api';
import toast from 'react-hot-toast';

const getDefaultInstanceData = async ({ email }) => {
  // console.log(`fetch getDefaultInstanceData`);

  try {
    const response = await api.post(`/asd-backend-user-v2`, {
      UserId: email,
      operation: 'getUserconfigData',
    });

    return response?.data;
  } catch (error) {
    throw new Error(`Couldn't get user data: ${error.message}`);
  }
};

export { getDefaultInstanceData };
