import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='w-[100vw] h-[100vh] flex justify-center hero'>
      <div className='mt-[7%]'>
        <h1 className='text-[150px] font-[900]'>Oops!</h1>
        <p className='font-[600]'>403 - ACCESS DENIED</p>
        <p className='max-w-[500px] mt-6'>Please contact your supervisor</p>
        <Link
          className='bg-[#5f249f] text-[#fff] px-4 py-2 rounded-lg ml-auto mr-auto mt-6 block max-w-[170px]'
          to='/'
        >
          Go to homepage
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
