// spell-checker:disable
export const UserRolesConsoles = {
  admin: 'admin',
  'asd-de-admin': 'supervisor',
  ccsuperuser: 'supervisor',
  cc_superuser: 'supervisor',
  supervisor: 'supervisor',
  traisupervisor: 'supervisor',
  trai_supervisor: 'supervisor',
  'trai supervisor': 'supervisor',
  cqmagent: 'cqm',
  cqmadmin: 'cqm',
  cqmanalyst: 'cqm',
  agent: 'agentccp',
  traiagent: 'agentccp',
  trai_agent: 'agentccp',
  agentfemrole: 'agentccp',
  'routing profile editor': '',
  lensrulemanager: '',
  'routing and flows read only': '',
};
