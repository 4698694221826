import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Paper from '@material-ui/core/Paper';
import { useApp } from '../../store/useApp';
import { InstanceIcon } from '../../Icons/InstanceIcon';
import { ExternalLink } from '../../Icons/ExternalLink';
import { getFederationConnectTokens } from '../../query/getFederationToken';
import { getAllUserData } from '../../query/getAllUserData';
import { getDefaultInstanceData } from '../../query/getDefaultInstanceData';

export const CQM = () => {
  const { UserId, ASD_Instance, MultipleInstance } = useApp(
    (state) => state.user
  );
  const [instances, setInstances] = useState(
    !MultipleInstance?.length ? [ASD_Instance] : MultipleInstance
  );

  // sync instances
  useEffect(() => {
    getDefaultInstanceData({ email: UserId })
      .then((res) => {
        const { MultipleInstance, ASD_Instance } = res;

        setInstances(
          !MultipleInstance?.length ? [ASD_Instance] : MultipleInstance
        );
      })
      .catch((error) => {
        toast.error(`Ouch, something went wrong... \n\n ${error.message}`);
      });
  }, []);

  const fetchConnectTokens = async ({ instanceId, instanceName }) => {
    const waitToast = toast.loading(
      `Please wait a while. You are being redirecting to ${instanceName.toUpperCase()} connect console...`,
      { duration: 10000 }
    );

    try {
      const userData = await getAllUserData({ authUserId: UserId });

      if (!userData.length) return toast(`No user data found...`);

      const { region, email } = userData.find((userInstance) => {
        const { instanceName: instanceNameUser } = userInstance;

        return instanceNameUser === instanceName;
      });

      if (!region) return toast(`No region found...`);
      if (!email) return toast(`No email found...`);

      const {
        AccessToken,
        RefreshToken,
        AccessTokenExpiration,
        RefreshTokenExpiration,
      } = await getFederationConnectTokens({
        instanceId,
        email,
        region,
      });

      if (
        !AccessToken &&
        !RefreshToken &&
        !AccessTokenExpiration &&
        !RefreshTokenExpiration
      )
        return toast(`No credentials found...`);

      const actionUrl = `https://${instanceName}.my.connect.aws/auth/sign-in?`;

      var form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', actionUrl);
      form.setAttribute('target', '_blank');

      var hiddenField = document.createElement('input');
      var credentials = {
        AccessToken,
        AccessTokenExpiration: new Date(AccessTokenExpiration).getTime(),
        RefreshToken,
        RefreshTokenExpiration: new Date(RefreshTokenExpiration).getTime(),
      };

      hiddenField = document.createElement('input');
      hiddenField.setAttribute('name', 'credentials');
      hiddenField.setAttribute('value', JSON.stringify(credentials));
      form.appendChild(hiddenField);

      hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'submit');
      hiddenField.setAttribute('value', 'submitBtn');
      hiddenField.setAttribute('id', 'submitBtn');
      form.appendChild(hiddenField);

      document.querySelector('#connectForm').appendChild(form);

      setTimeout(() => {
        toast.dismiss(waitToast);
        form.submit();

        // const connectConsoleURL = `https://dj6axkupbloat.cloudfront.net/connect-login.html?accessToken=${body.AccessToken}&accessTokenExpiration=${body.AccessTokenExpiration}&refreshToken=${body.RefreshToken}&refreshTokenExpiration=${body.RefreshTokenExpiration}&actionUrl=${actionUrl}`;
        // window.open(connectConsoleURL, '_blank');
      }, [3000]);
    } catch (error) {
      toast.dismiss(waitToast);
      toast.error(`Ouch, something went wrong... \n\n ${error.message}`);
    }
  };

  return (
    <>
      <Toaster
        position='top-right'
        toastOptions={{
          className: '',
          style: {
            textAlign: 'start',
          },
        }}
      />
      <div className='relative loginContainer'>
        {/* 			{loading && <Loading />} */}
        <div className='fullbgc'>
          <div style={{ position: 'absolute', bottom: '5%', left: '5%' }}>
            <div className='footer'>
              &copy; DXC Technology Company {new Date().getFullYear()}
            </div>
          </div>

          <div style={{ position: 'absolute', bottom: '5%', right: '5%' }}>
            <div className='footer' title='Version'>
              Agile Service Desk (ASD) Ver 2.0, Agent CCP Ver 2.1
            </div>
          </div>
        </div>
        <Paper elevation={3} className='rounded overlay vcenter font-["Arial"]'>
          <div className='left_login'>
            <div className='head'>
              <div className='grp164'></div>
              <div className='text'>Agile Service Desk</div>
            </div>
            <div className='subhead font-["Arial"]'>
              Agile Service Desk is a cloud-based contact center platform built
              by DXC using Amazon Connect and Amazon Web Services.
            </div>
            <div className='dxc'></div>
          </div>
          <div className='pr-6 right_login'>
            <div className='flex items-center gap-[10px] p-6  font-bold '>
              <h2 className='text-[24px] text-left'>
                You are successfully logged-in. Please <br></br> select AWS
                Connect instance to open.
              </h2>
            </div>
            <div className='flex flex-col gap-[50px] pt-[25px] p-6 text-[22px] '>
              {instances.map((instance) => {
                const [instanceId, instanceName] = instance.split(',');

                return (
                  <div
                    key={instanceId}
                    className='flex items-center justify-between w-full'
                  >
                    <div className='flex gap-[7px] items-center'>
                      <InstanceIcon width='30px' height='30px' />
                      <span className='text-[20px]'>
                        {instanceName?.toLowerCase()}
                      </span>
                    </div>

                    <button
                      className='font-bold p-0 text-[#00a3e1] border-none '
                      onClick={() =>
                        fetchConnectTokens({ instanceId, instanceName })
                      }
                    >
                      <span className='flex items-center font-normal gap-[7px]'>
                        <ExternalLink
                          width='23px'
                          height='23px'
                          className='text-[#6a6a6a]'
                        />
                        <span className='text-[20px]'>Open</span>
                      </span>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>
        <div
          id='connectForm'
          className='h-[0] w-[0] opacity-0 absolute top-0 left-0'
        ></div>
      </div>
    </>
  );
};
