/**
 * Copyright © 2019-2022 DXC. All rights reserved.
 */
import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const BootstrapButton = withStyles({
  root: {
    // boxShadow: "none",
    // textTransform: "none",
    // padding: "6px 12px",
    // height: "100%",
    // border: "1px solid",
    // lineHeight: 2.0,
    // backgroundColor: "#5F249F",
    // borderColor: "#5F249F",
    // color: "white",
    "&:hover.purpleButton": {
      backgroundColor: "#330072"
    },
    "&:hover.purpleButton": {
      backgroundColor: "#330072"
    },
    "&:focus": {
      boxShadow: "none"
    }
  }
})(Button);

const useStyles = makeStyles(theme => ({
  purpleButton: {
    margin: "0px 10px 0px 0px"
  }
}));

function PurpleButton({ onClick, title, style, disabled }) {
  const classes = useStyles();

  const handleEvent = event => {
    // Pass event to caller via the onChild2Event prop.
    // You can do something with the event, or just pass it.
    onClick(event);
  };

  return (
    <div className="buttonContainer">
      <BootstrapButton
        variant="contained"
        color="primary"
        onClick={handleEvent}
        className={`${classes.purpleButton} purpleButton submit`}
        style={style}
        disabled={disabled}
      >
        {title}
      </BootstrapButton>
    </div>
  );
}

PurpleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
  // onClose: PropTypes.func.isRequired,
  // open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default PurpleButton;
