import axios from 'axios';
import { useApp } from '../../store/useApp';

const baseURL = () => {
  return useApp.getState().environment.apiUrl;
};

const instance = axios.create({});

const idToken = localStorage.getItem('idToken');

instance.interceptors.request.use(function (config) {
  config.baseURL = baseURL();
  config.headers.Authorization = 'Bearer ' + idToken;
  return config;
});

export default instance;
