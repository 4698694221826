import api from '../utils/axios/api';
import toast from 'react-hot-toast';

const getAllUserData = async ({ authUserId }) => {
  // console.log(`fetch getAllUserData`);

  try {
    const result = await api.post(`/asd-backend-agentccp-userprofile-v2`, {
      UserId: authUserId,
    });

    const resultResponse = result.data;

    const filterData = resultResponse.reduce((acc, curr) => {
      const data = {
        instanceId: curr.ASD_Instance.split(',')[0],
        instanceName: curr.ASD_Instance.split(',')[1],
        email: curr.Email,
        userId: curr.UserIdId,
        firstName: curr.IdentityInfo.FirstName,
        lastName: curr.IdentityInfo.LastName,
        region: curr.region,
        companies: curr.Companies,
        adminGroups: curr.AdminGroups,
        securityProfiles: curr.SecurityProfiles,
        defaultSecurityProfile: curr.DefaultSecurityProfile,
        country: curr.azure_country?.toUpperCase(),
      };

      acc.push(data);

      return acc;
    }, []);

    return filterData;
  } catch (error) {
    throw new Error(`Couldn't get user data: ${error.message}`);
  }
};

export { getAllUserData };
