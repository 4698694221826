import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

const store = (set) => ({
  user: {},
  environment: {},
  auth: {},
  setUser: (object) =>
    set(
      (store) => ({
        user: { ...object },
      }),
      false,
      'setUser'
    ),
  setEnvironment: (object) =>
    set(
      (store) => ({
        environment: { ...object },
      }),
      false,
      'setEnvironment'
    ),
  setAuth: (object) =>
    set(
      (store) => ({
        auth: { ...object },
      }),
      false,
      'setAuth'
    ),
  resetUser: () =>
    set(
      (store) => ({
        user: {},
      }),
      false,
      'resetUser'
    ),
  resetEnvironment: () =>
    set(
      (store) => ({
        environment: {},
      }),
      false,
      'resetEnvironment'
    ),
  resetAuth: () =>
    set(
      (store) => ({
        auth: {},
      }),
      false,
      'resetEnvironment'
    ),
});

export const useApp = create(devtools(persist(store, { name: 'session' })));
