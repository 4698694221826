import api from '../utils/axios/api';
import toast from 'react-hot-toast';

const getFederationConnectTokens = async ({ instanceId, email, region }) => {
  // console.log(`getFederationConnectTokens`);

  try {
    const result = await api.post(
      `/asd-backend-get-connect-federation-token-v2`,
      {
        UserId: email,
        FederationType: 'CONNECT',
        InstanceId: instanceId,
        Region: region,
      }
    );

    const credentials = result?.data?.body?.FederationTokens?.Credentials;
    return credentials ?? {};
  } catch (error) {
    throw new Error(`Couldn't get chat tokens: ${error.message}`);
  }
};

export { getFederationConnectTokens };
