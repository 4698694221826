import React from 'react';

export const InstanceIcon = ({ width, height, className }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 17.79 21.434'
    >
      <path
        id='Path_579'
        data-name='Path 579'
        d='M4.1,11.978a.5.5,0,0,0,.547.319.55.55,0,0,0,.319-.319A8.406,8.406,0,0,1,7.1,8.653c1-1.139,2-2.232,2-4.1A4.628,4.628,0,0,0,4.554,0,4.628,4.628,0,0,0,0,4.554c0,1.867.956,2.96,2,4.1A7.873,7.873,0,0,1,4.1,11.978ZM4.554.911A3.779,3.779,0,0,1,8.2,4.554c0,1.5-.82,2.414-1.776,3.461a11.759,11.759,0,0,0-1.867,2.6,11.759,11.759,0,0,0-1.867-2.6C1.731,6.968.911,6.057.911,4.554A3.779,3.779,0,0,1,4.554.911Z'
        fill='currentColor'
      />
      <path
        id='Path_580'
        data-name='Path 580'
        d='M7.822,9.644A1.822,1.822,0,1,0,6,7.822,1.827,1.827,0,0,0,7.822,9.644Zm0-2.733a.911.911,0,1,1-.911.911A.914.914,0,0,1,7.822,6.911Z'
        transform='translate(-3.267 -3.267)'
        fill='currentColor'
      />
      <path
        id='Path_581'
        data-name='Path 581'
        d='M15.188,33.832h1.366a.413.413,0,0,0,.319-.137l.911-.911a.413.413,0,0,0,.137-.319v-1.64l.319-.319a.413.413,0,0,0,.137-.319v-.911a.43.43,0,0,0-.455-.455h-.273l-1.685-1.685A.413.413,0,0,0,15.644,27H13.366a.413.413,0,0,0-.319.137l-.911.911a.413.413,0,0,0-.137.319v1.366a.413.413,0,0,0,.137.319l.455.455a.413.413,0,0,0,.319.137H14.1l.638.638v2.1A.43.43,0,0,0,15.188,33.832ZM14.6,29.869a.413.413,0,0,0-.319-.137H13.093l-.182-.182v-1l.638-.638h1.913L17.147,29.6a.413.413,0,0,0,.319.137v.273l-.319.319a.413.413,0,0,0-.137.319v1.64l-.638.638h-.729V31.1a.413.413,0,0,0-.137-.319Z'
        transform='translate(-6.535 -14.703)'
        fill='currentColor'
      />
      <path
        id='Path_582'
        data-name='Path 582'
        d='M13,9.056A8.694,8.694,0,0,0,9.631,8.1l-.091.911A8.058,8.058,0,0,1,14.869,11.7h-1.64a.413.413,0,0,0-.319.137l-.774.774H10.952a.413.413,0,0,0-.319.137l-.774.774H9.13a1.342,1.342,0,0,0-1.366,1.366V15.8a.43.43,0,0,0,.455.455H9.4l1.685,1.685a.413.413,0,0,0,.319.137h.911a.413.413,0,0,0,.319-.137l.319-.319h.091l.774.774a.413.413,0,0,0,.319.137h2.6a7.981,7.981,0,0,1-15.8-1.594,7.853,7.853,0,0,1,.911-3.644l-.82-.41A8.891,8.891,0,1,0,13,9.056Zm1.321,8.562-.774-.774a.413.413,0,0,0-.319-.137h-.455a.413.413,0,0,0-.319.137l-.319.319H11.59L9.9,15.478a.413.413,0,0,0-.319-.137H8.675v-.455a.43.43,0,0,1,.455-.455h.911a.413.413,0,0,0,.319-.137l.774-.774h1.184a.413.413,0,0,0,.319-.137l.774-.774h2.1v-.091a7.778,7.778,0,0,1,1.366,4.418,3.447,3.447,0,0,1-.046.683Z'
        transform='translate(-0.021 -4.411)'
        fill='currentColor'
      />
    </svg>
  );
};
